(function(){
    'use strict';
    if(!window.addEventListener){return;}

    var regWhite = /\s+/g;
    var regSplitSet = /\s*\|\s+|\s+\|\s*/g;
    var regSource = /^(.+?)(?:\s+\[\s*(.+?)\s*\])?$/;
    var allowedBackgroundSize = {contain: 1, cover: 1};
    var proxyWidth = function(elem){
        var width = lazySizes.gW(elem, elem.parentNode);

        if(!elem._lazysizesWidth || width > elem._lazysizesWidth){
            elem._lazysizesWidth = width;
        }
        return elem._lazysizesWidth;
    };
    var getBgSize = function(elem){
        var bgSize;

        bgSize = (getComputedStyle(elem) || {getPropertyValue: function(){}}).getPropertyValue('background-size');

        if(!allowedBackgroundSize[bgSize] && allowedBackgroundSize[elem.style.backgroundSize]){
            bgSize = elem.style.backgroundSize;
        }

        return bgSize;
    };
    var createPicture = function(sets, elem, img){
        var picture = document.createElement('picture');
        var sizes = elem.getAttribute(lazySizesConfig.sizesAttr);
        var ratio = elem.getAttribute('data-ratio');
        var optimumx = elem.getAttribute('data-optimumx');

        if(elem._lazybgset && elem._lazybgset.parentNode == elem){
            elem.removeChild(elem._lazybgset);
        }

        Object.defineProperty(img, '_lazybgset', {
            value: elem,
            writable: true
        });
        Object.defineProperty(elem, '_lazybgset', {
            value: picture,
            writable: true
        });

        sets = sets.replace(regWhite, ' ').split(regSplitSet);

        picture.style.display = 'none';
        img.className = lazySizesConfig.lazyClass;

        if(sets.length == 1 && !sizes){
            sizes = 'auto';
        }

        sets.forEach(function(set){
            var source = document.createElement('source');

            if(sizes && sizes != 'auto'){
                source.setAttribute('sizes', sizes);
            }

            if(set.match(regSource)){
                source.setAttribute(lazySizesConfig.srcsetAttr, RegExp.$1);
                if(RegExp.$2){
                    source.setAttribute('media', lazySizesConfig.customMedia[RegExp.$2] || RegExp.$2);
                }
            }
            picture.appendChild(source);
        });

        if(sizes){
            img.setAttribute(lazySizesConfig.sizesAttr, sizes);
            elem.removeAttribute(lazySizesConfig.sizesAttr);
            elem.removeAttribute('sizes');
        }
        if(optimumx){
            img.setAttribute('data-optimumx', optimumx);
        }
        if(ratio) {
            img.setAttribute('data-ratio', ratio);
        }

        picture.appendChild(img);

        elem.appendChild(picture);
    };

    var proxyLoad = function(e){
        if(!e.target._lazybgset){return;}

        var image = e.target;
        var elem = image._lazybgset;
        var bg = image.currentSrc || image.src;

        if(bg){
            elem.style.backgroundImage = 'url('+ bg +')';
        }

        if(image._lazybgsetLoading){
            lazySizes.fire(elem, '_lazyloaded', {}, false, true);
            delete image._lazybgsetLoading;
        }
    };

    addEventListener('lazybeforeunveil', function(e){
        var set, image, elem;

        if(e.defaultPrevented || !(set = e.target.getAttribute('data-bgset'))){return;}

        elem = e.target;
        image = document.createElement('img');

        image.alt = '';

        image._lazybgsetLoading = true;
        e.detail.firesLoad = true;

        createPicture(set, elem, image);

        setTimeout(function(){
            lazySizes.loader.unveil(image);

            lazySizes.rAF(function(){
                lazySizes.fire(image, '_lazyloaded', {}, true, true);
                if(image.complete) {
                    proxyLoad({target: image});
                }
            });
        });

    });

    document.addEventListener('load', proxyLoad, true);

    window.addEventListener('lazybeforesizes', function(e){
        if(e.target._lazybgset && e.detail.dataAttr){
            var elem = e.target._lazybgset;
            var bgSize = getBgSize(elem);

            if(allowedBackgroundSize[bgSize]){
                e.target._lazysizesParentFit = bgSize;

                lazySizes.rAF(function(){
                    e.target.setAttribute('data-parent-fit', bgSize);
                    if(e.target._lazysizesParentFit){
                        delete e.target._lazysizesParentFit;
                    }
                });
            }
        }
    }, true);

    document.documentElement.addEventListener('lazybeforesizes', function(e){
        if(e.defaultPrevented || !e.target._lazybgset){return;}
        e.detail.width = proxyWidth(e.target._lazybgset);
    });
})();