/**
 * Post
 *
 * 01. Responsive Table
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
	'use strict';

    /**
     * Responsive Table
     */
    $('table').each(function () {
        var table = $(this),
            th = table.find('thead th'),
            headers = [];

        th.each(function () {
            headers.push($(this).text());
        });

        table.find('tbody tr').each(function () {
            var cells = $(this).find('td');

            headers.map(function (value, index) {
                cells.eq(index).attr('data-label', value);
            });
        });
    });


    /**
     * News Modal
     */
    $('.js-news').magnificPopup({
        type: 'inline',
        preloader: false,
        modal: true
    });
    $(document).on('click', '.news__dismiss a', function (e) {
        e.preventDefault();
        $.magnificPopup.close();
    });


    /**
     * Gallery
     */
    $('.gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Caricamento immagine #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
            enabled: true,
            navigateByImgClick: true,
            preload: [0,1] // Will preload 0 - before current, and 1 after the current image
        },
        image: {
            tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function(item) {
                return item.el.attr('title');
            }
        }
    });

}(jQuery));
