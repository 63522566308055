/**
 * Stripe.Com Navigation
 *
 * @link m/CodyHouse/stripe.com-navigation
 * @global jQuery
 * @author Matteo Casonato
 */

jQuery(document).ready(function ($) {
    function MorphDropDown(element) {
        this.element = element;
        this.mainNavigation = this.element.find('.js-menu');
        this.mainNavigationItems = this.mainNavigation.find('.has-dropdown');
        this.dropdownList = this.element.find('.dropdown-list');
        this.dropdownBg = this.dropdownList.find('.dropdown-list__bg');

        this.mainNavigationItems.each(function () {
            var li = $(this), a = li.find('a');

            a.append(' <svg><use xlink:href="#icons--chevron-down"></use></svg>');
            li.data('content', a.attr('rel'));
        });

        this.mq = this.checkMq();

        this.preparePositions();
        this.bindEvents();
    }

    MorphDropDown.prototype.checkMq = function () {
        //check screen size
        return window.getComputedStyle(this.element.get(0), '::before').getPropertyValue('content').replace(/'/g, "").replace(/"/g, "").split(', ')[0];
    };

    MorphDropDown.prototype.preparePositions = function () {
        var self = this;
        var dropDownLeft = this.dropdownList.removeAttr('style').offset().left;

        this.mainNavigationItems.each(function () {
            var li = $(this),
                selectedDropDown = self.dropdownList.find('#' + li.data('content')),
                selectedDropDownWidth = selectedDropDown.children('.dropdown-list__content').innerWidth();

            li.data('left', li.offset().left + li.innerWidth() / 2 - selectedDropDownWidth / 2 - dropDownLeft);
        });
    };

    MorphDropDown.prototype.bindEvents = function () {
        var self = this;
        //hover over an item in the main navigation
        this.mainNavigationItems.mouseenter(function (event) {
            //hover over one of the nav items -> show dropdown
            self.showDropdown($(this));
        }).mouseleave(function () {
            setTimeout(function () {
                //if not hovering over a nav item or a dropdown -> hide dropdown
                if (self.mainNavigation.find('.has-dropdown:hover').length === 0 && self.element.find('.dropdown-list:hover').length === 0) self.hideDropdown();
            }, 50);
        });

        //hover over the dropdown
        this.dropdownList.mouseleave(function () {
            setTimeout(function () {
                //if not hovering over a dropdown or a nav item -> hide dropdown
                (self.mainNavigation.find('.has-dropdown:hover').length === 0 && self.element.find('.dropdown-list:hover').length === 0 ) && self.hideDropdown();
            }, 50);
        });

        //click on an item in the main navigation -> open a dropdown on a touch device
        this.mainNavigationItems.on('touchstart', function (event) {
            var selectedDropdown = self.dropdownList.find('#' + $(this).data('content'));
            if (!self.element.hasClass('is-dropdown-visible') || !selectedDropdown.hasClass('is-active')) {
                event.preventDefault();
                self.showDropdown($(this));
            }
        });

        //on small screens, open navigation clicking on the menu icon
        this.element.on('click', '.nav__trigger', function (event) {
            event.preventDefault();
            self.element.toggleClass('is-nav-open');
        });

        $(window).smartresize(function () {
            self.preparePositions();
        });
    };

    MorphDropDown.prototype.showDropdown = function (item) {
        this.mq = this.checkMq();

        if (this.mq === 'desktop') {
            var self = this;
            var selectedDropdown = this.dropdownList.find('#' + item.data('content')),
                selectedDropdownHeight = selectedDropdown.innerHeight(),
                selectedDropdownWidth = selectedDropdown.children('.dropdown-list__content').innerWidth(),
                selectedDropdownLeft = item.data('left');

            //update dropdown position and size
            this.updateDropdown(selectedDropdown, parseInt(selectedDropdownHeight), selectedDropdownWidth, parseInt(selectedDropdownLeft));
            //add is-active class to the proper dropdown item
            this.element.find('.is-active').removeClass('is-active');
            selectedDropdown.addClass('is-active').removeClass('dropdown-list__item--left dropdown-list__item--right').prevAll().addClass('dropdown-list__item--left').end().nextAll().addClass('dropdown-list__item--right');
            item.addClass('is-active');
            //show the dropdown wrapper if not visible yet
            if (!this.element.hasClass('is-dropdown-visible')) {
                setTimeout(function () {
                    self.element.addClass('is-dropdown-visible');
                }, 10);
            }
        }
    };

    MorphDropDown.prototype.updateDropdown = function (dropdownItem, height, width, left) {
        this.dropdownList.css({
            '-moz-transform': 'translateX(' + left + 'px)',
            '-webkit-transform': 'translateX(' + left + 'px)',
            '-ms-transform': 'translateX(' + left + 'px)',
            '-o-transform': 'translateX(' + left + 'px)',
            'transform': 'translateX(' + left + 'px)',
            'width': width + 'px',
            'height': height + 'px'
        });

        this.dropdownBg.css({
            '-moz-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
            '-webkit-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
            '-ms-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
            '-o-transform': 'scaleX(' + width + ') scaleY(' + height + ')',
            'transform': 'scaleX(' + width + ') scaleY(' + height + ')'
        });
    };

    MorphDropDown.prototype.hideDropdown = function () {
        this.mq = this.checkMq();
        if (this.mq === 'desktop') {
            this.element.removeClass('is-dropdown-visible').find('.is-active').removeClass('is-active').end().find('.dropdown-list__item--left').removeClass('dropdown-list__item--left').end().find('.dropdown-list__item--right').removeClass('dropdown-list__item--right');
        }
    };

    MorphDropDown.prototype.resetDropdown = function () {
        this.mq = this.checkMq();
        if (this.mq === 'mobile') {
            this.dropdownList.removeAttr('style');
        }
    };

    var morphDropdowns = [];
    var nav = $('.js-nav');

    if (nav.length > 0) {
        nav.each(function () {
            //create a morphDropdown object for each .nav
            morphDropdowns.push(new MorphDropDown($(this)));
        });

        var resizing = false;

        //on resize, reset dropdown style property
        updateDropdownPosition();
        $(window).on('resize', function () {
            if (!resizing) {
                resizing = true;
                (!window.requestAnimationFrame) ? setTimeout(updateDropdownPosition, 300) : window.requestAnimationFrame(updateDropdownPosition);
            }
        });

        function updateDropdownPosition() {
            morphDropdowns.forEach(function (element) {
                element.resetDropdown();
            });

            resizing = false;
        }
    }
});