/**
 * Global
 *
 * 01. Pongho Ajax Form
 * 02. SVG
 * 03. Google Maps
 * 04. Cookie iFrame
 *
 * @global jQuery
 * @author Matteo Casonato
 */


/**
 * Pongho Ajax Form
 *
 * @requires plugins/pongho-form.js
 */
(function ($) {
    'use strict';

    $('.js-ajaxform').ponghoForm({
        wrapperClass: 'ajax-form-wrapper',
        onSubmit: function (data, form) {
            if (window.ga) {
                // https://developers.google.com/analytics/devguides/collection/analyticsjs/events
                window.ga(
                    'send',
                    {
                        'hitType': 'event', // Required.
                        'eventCategory': 'form', // Required.
                        'eventAction': 'submit', // Required.
                        'eventLabel': form.data('ga-label') || form.prop('name'),
                        'page': window.location.pathname
                    }
                );
            }
        }
    });

}(jQuery));


/**
 * SVG
 *
 * @requires libraries/svg4everybody.js
 * @requires ie/svg4everybody.legacy.js
 */
(function ($) {
    'use strict';

    svg4everybody();

}(jQuery));


/**
 * Google Maps
 *
 * @summary Mappa personalizzata con https://snazzymaps.com/
 * @requires plugins/jquery.googlemaps.js
 */
(function ($) {
    'use strict';

    var maps = $('.js-gmap');

    if (!maps.length) {
        return;
    }

    window.onFirstUserInteraction(function(){
        var d = document,
            t = 'script';

        function l(u) {
            var j = d.createElement(t),
                s = d.getElementsByTagName(t)[0];

            j.src = u;
            j.async = 1;
            s.parentNode.insertBefore(j, s);
        }

        l('//maps.googleapis.com/maps/api/js?key=AIzaSyAO4ycwmVie3JhnpJx7-_b_kg629w_g7ZU&v=3.exp&callback=initialize&libraries=geometry');
    });

    window.initialize = function () {
        maps.googlemaps({
            style: [
                {
                    "featureType": "administrative",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#444444"
                        }
                    ]
                },
                {
                    "featureType": "administrative.country",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "saturation": "0"
                        },
                        {
                            "lightness": "10"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#f2f2f2"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "poi.attraction",
                    "elementType": "geometry",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.business",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.government",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.medical",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.park",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.place_of_worship",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.school",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "poi.sports_complex",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "all",
                    "stylers": [
                        {
                            "saturation": -100
                        },
                        {
                            "lightness": 45
                        }
                    ]
                },
                {
                    "featureType": "road.highway",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "simplified"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#4eccff"
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#ace2ff"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "lightness": "-46"
                        },
                        {
                            "saturation": "-61"
                        }
                    ]
                }
            ]
        });
    };

}(jQuery));
